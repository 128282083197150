import React from "react";
import theme from "theme";
import { Theme, Text, Hr, Box, Section, Image } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import * as Components from "components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"about"} />
		<Helmet>
			<title>
				Про нас | Відкрийте для себе NewCreative Pulse
			</title>
			<meta name={"description"} content={"Розкрийте свій потенціал всередині"} />
			<meta property={"og:title"} content={"Про нас | Відкрийте для себе NewCreative Pulse"} />
			<meta property={"og:description"} content={"Розкрийте свій потенціал всередині"} />
			<meta property={"og:image"} content={"https://uploads.quarkly.io/66f139cc9e74900024fd1b84/images/2-1.jpg?v=2024-09-23T13:07:13.313Z"} />
			<link rel={"shortcut icon"} href={"https://uploads.quarkly.io/66f139cc9e74900024fd1b84/images/6422889.png?v=2024-09-23T12:46:53.526Z"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://uploads.quarkly.io/66f139cc9e74900024fd1b84/images/6422889.png?v=2024-09-23T12:46:53.526Z"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://uploads.quarkly.io/66f139cc9e74900024fd1b84/images/6422889.png?v=2024-09-23T12:46:53.526Z"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://uploads.quarkly.io/66f139cc9e74900024fd1b84/images/6422889.png?v=2024-09-23T12:46:53.526Z"} />
			<link rel={"apple-touch-startup-image"} href={"https://uploads.quarkly.io/66f139cc9e74900024fd1b84/images/6422889.png?v=2024-09-23T12:46:53.526Z"} />
			<meta name={"msapplication-TileImage"} content={"https://uploads.quarkly.io/66f139cc9e74900024fd1b84/images/6422889.png?v=2024-09-23T12:46:53.526Z"} />
		</Helmet>
		<Components.Header />
		<Section background="url(https://uploads.quarkly.io/66f139cc9e74900024fd1b84/images/4-1.jpg?v=2024-09-23T13:07:13.326Z) center/cover" padding="140px 0" sm-padding="40px 0" quarkly-title="About-16">
			<Override slot="SectionContent" align-items="center" justify-content="center" sm-min-width="280px" />
			<Box
				padding="50px 80px 50px 50px"
				color="--dark"
				display="flex"
				flex-direction="column"
				align-items="center"
				background="#ffffff"
				sm-padding="50px 24px 50px 24px"
			>
				<Text margin="0 0 16px 0" as="h2" font="--headline2">
					Наша подорож, щоб покращити ваші можливості
				</Text>
				<Hr
					min-height="10px"
					margin="0px 0px 0px 0px"
					border-color="--color-dark"
					border-width="3px 0 0 0"
					max-width="50px"
					width="100%"
				/>
				<Text font="--base" text-align="center">
					У NewCreative Pulse ми більше, ніж тренажерний зал – ми притулок для зростання, сили та спільноти. Народився з простої, але глибокої ідеї: створити фітнес-середовище, яке сприяє прогресу та благополуччю для кожного. Ми позбавилися непотрібного й зосередилися на тому, що справді має значення – вашій подорожі до здоровішого та сильнішого вас. Наша історія не написана на камені, вона розвивається з кожним учасником, який проходить через наші двері, з кожною досягнутою ціллю та кожним подоланим викликом.
				</Text>
			</Box>
		</Section>
		<Section padding="120px 0 130px 0" lg-padding="80px 0 90px 0" quarkly-title="Stages/Steps-4">
			<Text font="--headline2" margin="0px 0px 40px 0px" color="--darkL2" text-align="center">
				Чому NewCreative Pulse{" "}
			</Text>
			<Box
				min-width="100px"
				min-height="100px"
				display="grid"
				grid-template-columns="repeat(3, 1fr)"
				md-grid-template-columns="1fr"
				md-grid-template-rows="auto"
				md-grid-gap={0}
				lg-padding="0px 0 0px 0"
			>
				<Box
					min-width="100px"
					min-height="100px"
					display="flex"
					flex-direction="row"
					padding="0px 50px 30px 0"
					sm-padding="0px 0 34px 0"
					lg-padding="0px 25px 0 0"
					md-margin="0px 0px 30px 0px"
					sm-margin="0px 0px 20px 0px"
				>
					<Box min-width="100px" min-height="100px" margin="0px 0px 0px 0">
						<Text
							margin="0 0 15px 25px"
							border-color="--color-light"
							color="--darkL1"
							font="normal 500 22px/1.2 --fontFamily-sans"
							text-align="left"
						>
							Інклюзивне середовище:{" "}
						</Text>
						<Text
							margin="0 0 0 25px"
							font="normal 400 16px/1.5 --fontFamily-sansHelvetica"
							color="#65696d"
							text-align="left"
							border-color="#7a7c7f"
						>
							вітаються всі, незалежно від рівня фізичної підготовки та досвіду. Це ваш безпечний простір для дослідження, навчання та розвитку.
						</Text>
					</Box>
				</Box>
				<Box
					min-width="100px"
					min-height="100px"
					display="flex"
					flex-direction="row"
					padding="0px 50px 30px 0"
					sm-padding="0px 0 34px 0"
					lg-padding="0px 25px 0 0"
					md-margin="0px 0px 30px 0px"
					sm-margin="0px 0px 20px 0px"
				>
					<Box min-width="100px" min-height="100px" margin="0px 0px 0px 0">
						<Text
							margin="0 0 15px 25px"
							border-color="--color-light"
							color="--darkL1"
							font="normal 500 22px/1.2 --fontFamily-sans"
							text-align="left"
						>
							Експертне керівництво без залякування:{" "}
						</Text>
						<Text
							margin="0 0 0 25px"
							font="normal 400 16px/1.5 --fontFamily-sansHelvetica"
							color="#65696d"
							text-align="left"
							border-color="#7a7c7f"
						>
							наші тренери тут, щоб підтримати, а не залякати. Вони є вашими партнерами в прокладанні шляху до ваших фітнес-цілей.
						</Text>
					</Box>
				</Box>
				<Box
					min-width="100px"
					min-height="100px"
					display="flex"
					flex-direction="row"
					padding="0px 50px 30px 0"
					sm-padding="0px 0 0 0"
					lg-padding="0px 25px 0 0"
					md-margin="0px 0px 30px 0px"
					sm-margin="0px 0px 20px 0px"
				>
					<Box min-width="100px" min-height="100px" margin="0px 0px 0px 0">
						<Text
							margin="0 0 15px 25px"
							border-color="--color-light"
							color="--darkL1"
							font="normal 500 22px/1.2 --fontFamily-sans"
							text-align="left"
						>
							Залучення поза вправами:{" "}
						</Text>
						<Text
							margin="0 0 0 25px"
							font="normal 400 16px/1.5 --fontFamily-sansHelvetica"
							color="#65696d"
							text-align="left"
							border-color="#7a7c7f"
						>
							приєднуйтесь до семінарів, громадських заходів і групових занять, які сприяють зміцненню зв’язків і збагачують вашу подорож оздоровленням.
						</Text>
					</Box>
				</Box>
				<Box
					min-width="100px"
					min-height="100px"
					display="flex"
					flex-direction="row"
					padding="0px 50px 30px 0"
					sm-padding="0px 0 0 0"
					lg-padding="0px 25px 0 0"
					md-margin="0px 0px 30px 0px"
					sm-margin="0px 0px 20px 0px"
				>
					<Box min-width="100px" min-height="100px" margin="0px 0px 0px 0">
						<Text
							margin="0 0 15px 25px"
							border-color="--color-light"
							color="--darkL1"
							font="normal 500 22px/1.2 --fontFamily-sans"
							text-align="left"
						>
							Зосередьтеся на самопочутті:{" "}
						</Text>
						<Text
							margin="0 0 0 25px"
							font="normal 400 16px/1.5 --fontFamily-sansHelvetica"
							color="#65696d"
							text-align="left"
							border-color="#7a7c7f"
						>
							ми надаємо пріоритет вашому психічному та фізичному благополуччю, пропонуючи цілісний підхід до фітнесу, який збалансовує фізичні вправи, харчування та відпочинок.
						</Text>
					</Box>
				</Box>
				<Box
					min-width="100px"
					min-height="100px"
					display="flex"
					flex-direction="row"
					padding="0px 50px 30px 0"
					sm-padding="0px 0 0 0"
					lg-padding="0px 25px 0 0"
					md-margin="0px 0px 30px 0px"
					sm-margin="0px 0px 20px 0px"
				>
					<Box min-width="100px" min-height="100px" margin="0px 0px 0px 0">
						<Text
							margin="0 0 15px 25px"
							border-color="--color-light"
							color="--darkL1"
							font="normal 500 22px/1.2 --fontFamily-sans"
							text-align="left"
						>
							Сталі практики:{" "}
						</Text>
						<Text
							margin="0 0 0 25px"
							font="normal 400 16px/1.5 --fontFamily-sansHelvetica"
							color="#65696d"
							text-align="left"
							border-color="#7a7c7f"
						>
							ми віримо в турботу про нашу планету так само, як ми дбаємо про своє тіло. У нашому тренажерному залі використовуються екологічно чисті практики без шкоди для якості чи досвіду.
						</Text>
					</Box>
				</Box>
			</Box>
		</Section>
		<Section padding="65px 0 65px 0" sm-padding="60px 0 60px 0" quarkly-title="Images-13">
			<Override slot="SectionContent" flex-direction="row" flex-wrap="wrap" />
			<Box
				padding="16px 16px 16px 16px"
				lg-order="-2"
				lg-width="100%"
				width="100%"
				margin="0px 0px 0px 0px"
				sm-padding="0px 0px 0px 0px"
				sm-margin="0px 0px 30px 0px"
				flex-direction="column"
				display="flex"
			>
				<Box
					overflow-x="hidden"
					position="relative"
					hover-transform="translateY(-10px)"
					width="100%"
					height="auto"
					overflow-y="hidden"
					transform="translateY(0px)"
					transition="transform 0.2s ease-in-out 0s"
					padding="0px 0px 70% 0px"
				>
					<Image
						position="absolute"
						display="block"
						width="100%"
						left={0}
						bottom="0px"
						min-height="100%"
						src="https://uploads.quarkly.io/66f139cc9e74900024fd1b84/images/1-3.jpg?v=2024-09-23T13:07:13.310Z"
						object-fit="cover"
						top="auto"
						right={0}
						srcSet="https://smartuploads.quarkly.io/66f139cc9e74900024fd1b84/images/1-3.jpg?v=2024-09-23T13%3A07%3A13.310Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/66f139cc9e74900024fd1b84/images/1-3.jpg?v=2024-09-23T13%3A07%3A13.310Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/66f139cc9e74900024fd1b84/images/1-3.jpg?v=2024-09-23T13%3A07%3A13.310Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/66f139cc9e74900024fd1b84/images/1-3.jpg?v=2024-09-23T13%3A07%3A13.310Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/66f139cc9e74900024fd1b84/images/1-3.jpg?v=2024-09-23T13%3A07%3A13.310Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/66f139cc9e74900024fd1b84/images/1-3.jpg?v=2024-09-23T13%3A07%3A13.310Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/66f139cc9e74900024fd1b84/images/1-3.jpg?v=2024-09-23T13%3A07%3A13.310Z&quality=85&w=3200 3200w"
						sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
					/>
				</Box>
			</Box>
			<Box
				sm-padding="0px 0px 0px 0px"
				padding="16px 16px 16px 16px"
				flex-direction="column"
				lg-align-items="center"
				lg-margin="0px 0px 0px 0px"
				lg-width="100%"
				sm-margin="0px 0px 0px 0px"
				display="flex"
				width="40%"
				align-items="flex-start"
			>
				<Box
					transform="translateY(0px)"
					transition="transform 0.2s ease-in-out 0s"
					padding="0px 0px 60% 0px"
					width="100%"
					height="auto"
					overflow-x="hidden"
					overflow-y="hidden"
					position="relative"
					hover-transform="translateY(-10px)"
				>
					<Image
						width="100%"
						right={0}
						min-height="100%"
						top="auto"
						left={0}
						bottom="0px"
						src="https://uploads.quarkly.io/66f139cc9e74900024fd1b84/images/4-2.jpg?v=2024-09-23T13:07:13.305Z"
						object-fit="cover"
						position="absolute"
						display="block"
						srcSet="https://smartuploads.quarkly.io/66f139cc9e74900024fd1b84/images/4-2.jpg?v=2024-09-23T13%3A07%3A13.305Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/66f139cc9e74900024fd1b84/images/4-2.jpg?v=2024-09-23T13%3A07%3A13.305Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/66f139cc9e74900024fd1b84/images/4-2.jpg?v=2024-09-23T13%3A07%3A13.305Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/66f139cc9e74900024fd1b84/images/4-2.jpg?v=2024-09-23T13%3A07%3A13.305Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/66f139cc9e74900024fd1b84/images/4-2.jpg?v=2024-09-23T13%3A07%3A13.305Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/66f139cc9e74900024fd1b84/images/4-2.jpg?v=2024-09-23T13%3A07%3A13.305Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/66f139cc9e74900024fd1b84/images/4-2.jpg?v=2024-09-23T13%3A07%3A13.305Z&quality=85&w=3200 3200w"
						sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
					/>
				</Box>
			</Box>
			<Box
				display="flex"
				flex-direction="row"
				flex-wrap="wrap"
				width="60%"
				lg-width="100%"
				lg-order="-1"
			>
				<Box
					display="flex"
					align-items="flex-start"
					lg-width="100%"
					sm-margin="0px 0px 0px 0px"
					width="100%"
					flex-direction="column"
					lg-align-items="center"
					lg-margin="0px 0px 0px 0px"
					sm-padding="0px 0px 0px 0px"
					padding="8px 16px 8px 16px"
				>
					<Text
						lg-text-align="center"
						sm-font="--headline3"
						margin="20px 0px 0px 0px"
						color="--darkL2"
						font="--headline3"
						md-text-align="left"
					>
						Відправляйтесь у подорож, яка трансформується
					</Text>
				</Box>
				<Box
					align-items="flex-start"
					lg-width="100%"
					lg-align-items="center"
					lg-margin="0px 0px 0px 0px"
					sm-padding="0px 0px 0px 0px"
					width="50%"
					flex-direction="column"
					padding="16px 16px 16px 16px"
					display="flex"
					sm-margin="0px 0px 0px 0px"
				>
					<Text
						margin="0px 0px 0px 0px"
						color="--grey"
						font="--base"
						lg-text-align="center"
						sm-margin="0px 0px 30px 0px"
						md-text-align="left"
					>
						У NewCreative Pulse ваша історія – це наша історія. Ми запрошуємо вас створити разом з нами свою розповідь про силу, стійкість і спільноту. Станьте частиною чогось більшого – руху до особистих досягнень і колективного благополуччя. Давайте разом втілимо ваші мрії в реальність.
					</Text>
				</Box>
				<Box
					lg-margin="0px 0px 0px 0px"
					sm-padding="0px 0px 0px 0px"
					padding="16px 16px 16px 16px"
					display="flex"
					lg-align-items="center"
					align-items="flex-start"
					lg-width="100%"
					sm-margin="0px 0px 0px 0px"
					width="50%"
					flex-direction="column"
					lg-padding="0px 16px 16px 16px"
				>
					<Text
						lg-text-align="center"
						sm-margin="0px 0px 30px 0px"
						margin="0px 0px 0px 0px"
						color="--grey"
						font="--base"
						md-text-align="left"
					>
						Наша відданість виходить за рамки ваг і тренажерів. Йдеться про створення досвіду, який мотивує, надихає та підтримує. З того моменту, як ви увійшли в NewCreative Pulse, ви відчуєте різницю.{" "}
					</Text>
				</Box>
			</Box>
		</Section>
		<Components.Footer />
		<RawHtml>
			<style place={"endOfHead"} rawKey={"65323428c5fd0f0020b15081"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});